<template>
  <div>
    <div class="uk-child-width-1-4@m uk-child-width-1-2@s uk-grid-small uk-grid">
      <div class="uk-grid-width-1-4" v-if="dashboardConfig.data.ticketNumber">
        <TicketNumber/>
      </div>
      <div class="uk-grid-width-1-4" v-if="dashboardConfig.data.quotationNumber">
        <QuotationNumber/>
      </div>
      <div class="uk-grid-width-1-4" v-if="dashboardConfig.data.ticketNumberPerCity">
        <TicketNumberPerCity/>
      </div>
      <div class="uk-grid-width-1-4" v-if="dashboardConfig.data.averageResponseTime">
        <AverageResponseTime/>
      </div>
    </div>

    <div class="uk-child-width-1-2@m uk-grid-small uk-grid uk-margin-small-top">
      <div class="uk-grid-width-1-2" v-if="dashboardConfig.data.ticketMap">
        <TicketMap :update-in-progress="dashboardData.updateInProgress"/>
      </div>
      <div class="uk-grid-width-1-2" v-if="dashboardConfig.data.missionOrderMap">
        <MissionOrderMap :update-in-progress="dashboardData.updateInProgress" />
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { mapState } from "vuex";
import TicketNumber from "../components/dashboard/TicketNumber.vue";
import QuotationNumber from "../components/dashboard/QuotationNumber.vue";
import AverageResponseTime from "../components/dashboard/AverageResponseTime.vue";
import TicketNumberPerCity from "../components/dashboard/TicketNumberPerCity.vue";
import TicketMap from "../components/dashboard/TicketMap.vue";
import MissionOrderMap from "../components/dashboard/MissionOrderMap.vue";

export default {
  name: "HomeDashboard",
  components: {
    TicketNumber: TicketNumber,
    QuotationNumber: QuotationNumber,
    AverageResponseTime: AverageResponseTime,
    TicketMap: TicketMap,
    TicketNumberPerCity: TicketNumberPerCity,
    MissionOrderMap: MissionOrderMap
  },
  computed: {
    ...mapState('dashboard', ['dashboardData', 'dashboardConfig']),
  },
  mounted() {
      Promise.all([
          store.dispatch('dashboard/getDashboardConfigData'),
          store.dispatch('dashboard/getDashboardData')
      ])
  },
}
</script>

<style scoped>
</style>
