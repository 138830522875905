<template>
  <div>
    <div class="uk-card-default uk-card-hover uk-card-body uk-padding-small">
      Tickets
      <div uk-spinner="ratio:0.75" v-if="dashboardData.updateInProgress"></div>
      <div ref="map" style="height: 60vh; min-height: 250px"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import "overlapping-marker-spiderfier-leaflet/dist/oms";

export default {
  name: "TicketMap",
  data: () => ({
    map: null,
    markerGroup: null,
  }),
  computed: {
    ...mapState('dashboard', ['dashboardData']),
  },
  watch: {
    tickets() {
      this.refreshMarkers()
      this.map.flyTo(...this.calculateCenterAndZoom())
    }
  },
  methods: {
    initializeMap() {
      this.map = this.$leaflet.map(this.$refs.map, {
        center: [46.7111, 1.7191],
        zoom: 6,
        gestureHandling: true,
      })
      this.$leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(this.map)
      this.map.flyTo(...this.calculateCenterAndZoom())
    },
    generateMarker(myCustomColor) {
      const markerHtmlStyles = (myCustomColor) => `
      background-color: ${myCustomColor};
      width: 16px;
      height: 16px;
      display: block;
      position: relative;
      border-radius: 16px 16px 0;
      transform: rotate(45deg);
      border: 1px solid #FFFFFF`

      return this.$leaflet.divIcon({
        className: "my-custom-pin",
        iconAnchor: [8, 0],
        labelAnchor: [0, 0],
        popupAnchor: [0, 0],
        html: `<span style="${markerHtmlStyles(myCustomColor)}" />`
      })
    },
    refreshMarkers() {
      const oms = new window.OverlappingMarkerSpiderfier(this.map, { keepSpiderfied: true });

      if (this.markerGroup) {
        this.map.removeLayer(this.markerGroup)
      }

      this.markerGroup = this.$leaflet.layerGroup()

      this.map.addLayer(this.markerGroup)

      for (let i = 0; i < this.dashboardData.data.ticketMap.length; i++) {
        let ticket = this.dashboardData.data.ticketMap[i]

        let loc = new this.$leaflet.LatLng(ticket.lat, ticket.lng)

        let marker = new this.$leaflet.marker(loc, {
          icon: this.generateMarker('#0080ff')
        })

        this.map.addLayer(marker.addTo(this.markerGroup).bindPopup(this.generatePopupMarker(ticket)))

        oms.addMarker(marker)

        const self = this
        oms.addListener('spiderfy', function () {
          self.map.closePopup();
        });
      }
    },
    generatePopupMarker(ticket) {
      let popupText = document.createElement('div')
      popupText.appendChild(document.createTextNode(`${ticket.address}`))

      let popupActionContainer = document.createElement('div')
      popupActionContainer.classList.add('text-end', 'mt-2')

      let popupActionView = document.createElement('button')
      popupActionView.appendChild(document.createTextNode(`voir le ticket`))
      popupActionView.classList.add('btn', 'btn-sm', 'btn-outline-primary')
      popupActionView.addEventListener('click', () => {
        this.$router.push({ name: 'ticket_view', params: { ticketId: ticket.id } })
      })

      let popupActionNavigate = document.createElement('a')
      popupActionNavigate.appendChild(document.createTextNode(`naviguer`))
      popupActionNavigate.classList.add('btn', 'btn-sm', 'btn-outline-primary', 'me-3')
      popupActionNavigate.setAttribute('href', this.navUrl(ticket.address))
      popupActionNavigate.setAttribute('role', 'button')
      popupActionNavigate.setAttribute('target', '_blank')

      popupActionContainer.appendChild(popupActionNavigate)
      popupActionContainer.appendChild(popupActionView)

      let popup = document.createElement('div')
      popup.appendChild(popupText)
      popup.appendChild(popupActionContainer)

      return popup
    },
    calculateCenterAndZoom() {
      if (this.dashboardData.data.ticketMap.length < 1) {
        return [[46.7111, 1.7191], 6]
      }

      let lng = [
        parseFloat(this.dashboardData.data.ticketMap[0].lng),
        parseFloat(this.dashboardData.data.ticketMap[0].lng),
      ]

      let lat = [
        parseFloat(this.dashboardData.data.ticketMap[0].lat),
        parseFloat(this.dashboardData.data.ticketMap[0].lat),
      ]

      for (let i = 0; i < this.dashboardData.data.ticketMap.length; i++) {
        let moLat = parseFloat(this.dashboardData.data.ticketMap[i].lat)
        let moLng = parseFloat(this.dashboardData.data.ticketMap[i].lng)
        if (moLng < lng[0]) {
          lng[0] = moLng
        }
        if (moLng > lng[1]) {
          lng[1] = moLng
        }
        if (moLat < lat[0]) {
          lat[0] = moLat
        }
        if (moLat > lat[1]) {
          lat[1] = moLat
        }
      }

      let center = [(lat[0] + lat[1]) / 2, (lng[0] + lng[1]) / 2]
      // let zoom = Math.round(1 / Math.max(Math.abs(lng[0] - lng[1]), Math.abs(lat[0] - lat[1])))
      // todo find a way to determine zoom value to apply
      // todo may be fin the solution : map.fitBounds(group.getBounds());
      // todo @see https://gis.stackexchange.com/a/76131

      return [center, 6]
    },
    navUrl(address) {
      return 'https://www.google.com/maps/dir//' + address
    }
  },
  mounted() {
    this.initializeMap()
    this.refreshMarkers()
  },
}
</script>

<style scoped>
</style>

<style>
div.leaflet-control-attribution {
  display: none !important;
}
</style>